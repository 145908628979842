<template>
  <v-card data-cy="user_settings-menu">
    <v-divider></v-divider>
    <v-list subheader>
      <v-subheader>Account</v-subheader>
      <v-list-tile v-for="(item, key) in userDetails" avatar :key="key">
        <v-list-tile-avatar>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>{{ item.sub }}</v-list-tile-sub-title>
          <v-list-tile-title>{{ user[key] }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile avatar>
        <v-list-tile-avatar>
          <v-icon>perm_identity</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>Role</v-list-tile-sub-title>
          <v-list-tile-title>{{ roles[role.name] }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile avatar v-if="client && client.name">
        <v-list-tile-avatar>
          <v-icon>apartment</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>Client</v-list-tile-sub-title>
          <v-list-tile-title>{{ client.name }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
    <v-divider></v-divider>
    <v-list subheader>
      <v-subheader>Actions</v-subheader>
      <v-list-tile
        to="/users-management"
        v-if="isAdmin"
        data-cy="nav-users-managements"
      >
        <v-list-tile-action>
          <v-icon>people</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>User Management</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile @click="clickModifyProfile('modifyProfile')">
        <v-list-tile-action disabled>
          <v-icon>vpn_key</v-icon>
        </v-list-tile-action>
        <v-list-tile-title>Modify Profile</v-list-tile-title>
      </v-list-tile>
      <v-list-tile @click="openDialog('logout')" data-cy="logout">
        <v-list-tile-action>
          <v-icon>exit_to_app</v-icon>
        </v-list-tile-action>
        <v-list-tile-title>Log Out</v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-card>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import debounce from 'lodash/debounce'
import { roles } from '@/components/Mixins/constants'

export default {
  name: 'UserMenu',
  data: () => ({
    userDetails: {
      name: { sub: 'Name', icon: 'info' },
      email: { sub: 'Email', icon: 'email' }
    },
    roles,
  }),
  computed: {
    ...mapState('users', ['status', 'user', 'client']),
    ...mapGetters('users', ['isAdmin', 'role']),
    ...mapGetters('sites', ['activeSite', 'siteCenter'])
  },
  methods: {
    openDialog(name) {
      this.$store.commit('users/SET_STATUS', null)
      return this.$bus.$emit('openDialog', name)
    },
    clickModifyProfile(dialogName) {
      this.$bus.$emit('OPEN_MODIFY_PROFILE_DIALOG', {
        user: {...this.user}
      })
      this.openDialog(dialogName)
    },
    stopPropagation(event) {
      event.stopPropagation()
    }
  }
}
</script>
